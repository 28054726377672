/* eslint-disable max-len */
import i18n from '@/plugins/i18n';
import Section from '@/components/Section.vue';

const { t } = i18n.global;

export default [
  {
    path: '/',
    redirect: { name: 'login' },
    component: () => import('./views/Index.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('./views/Login.vue'),
      },
      {
        name: 'password',
        path: '/password',
        component: () => import('./views/Password.vue'),
      },
      {
        name: 'password-reset',
        path: '/password-reset/:token',
        component: () => import('./views/PasswordReset.vue'),
        props: (route) => ({
          token: route.params.token,
          action: 'reset-password',
          email: route.query.email,
        }),
      },
      {
        name: 'activate',
        path: '/activate/:token',
        component: () => import('./views/PasswordReset.vue'),
        props: (route) => ({
          token: route.params.token,
          action: 'activate',
          email: route.query.email,
        }),
      },
    ],
  },
  {
    path: '/contract/sign',
    name: 'contract-sign',
    component: () => import('./views/ContractPreview.vue'),
    props: (route) => ({
      url: route.query.url,
    }),
  },
  {
    path: '/contract/signed',
    name: 'contract-signed',
    component: () => import('./views/ContractConfirmation.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('./views/Admin.vue'),
    redirect: { name: 'admin-dashboard' },
    children: [
      {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        component: () => import('./views/admin/Dashboard.vue'),
      },
      {
        name: 'admin-clients',
        path: 'clients',
        component: Section,
        redirect: { name: 'admin-clients-overview' },
        meta: { search: true },
        children: [
          {
            name: 'admin-clients-overview',
            path: '',
            component: () => import('@/views/admin/Clients.vue'),
          },
          {
            path: 'add',
            name: 'admin-clients-add',
            component: () => import('@/views/admin/ClientsAdd.vue'),
            props: () => ({
              overview: 'admin-clients-overview',
            }),
          },
          {
            path: ':client',
            name: 'admin-clients-view',
            props: (route) => ({ id: +route.params.client }),
            component: () => import('@/views/admin/ClientsShow.vue'),
            redirect: { name: 'admin-clients-edit' },
            children: [
              {
                name: 'admin-clients-edit',
                path: 'edit',
                component: () => import('@/views/admin/ClientsEdit.vue'),
              },
              {
                name: 'admin-clients-mares',
                path: 'mares',
                component: Section,
                redirect: { name: 'admin-clients-mares-overview' },
                children: [
                  {
                    name: 'admin-clients-mares-overview',
                    path: 'mares',
                    component: () => import('@/views/admin/ClientsMares.vue'),
                    meta: { search: true },
                    props: () => ({ route: 'admin-clients-mare' }),
                  },
                  {
                    name: 'admin-clients-mares-add',
                    path: 'add',
                    component: () => import('@/views/admin/MaresAdd.vue'),
                    props: () => ({
                      overview: 'admin-clients-mares',
                    }),
                  },
                  {
                    name: 'admin-clients-mare',
                    path: ':mare',
                    component: () => import('@/views/admin/MaresShow.vue'),
                    props: (route) => ({
                      id: +route.params.mare,
                      overview: 'admin-clients-mares',
                      tabs: [
                        { name: t('general.details'), key: 'admin-clients-mares-edit' },
                        { name: t('mares.fertility'), key: 'admin-clients-mares-fertility' },
                        { name: t('activities.title'), key: 'admin-clients-mares-activities' },
                        { name: t('medications.title'), key: 'admin-clients-mares-medication' },
                        { name: t('stablings.title'), key: 'admin-clients-mares-stabling' },
                      ],
                    }),
                    redirect: { name: 'admin-clients-mares-edit' },
                    children: [
                      {
                        name: 'admin-clients-mares-edit',
                        path: 'details',
                        component: () => import('@/views/admin/MaresEdit.vue'),
                      },
                      {
                        name: 'admin-clients-mares-fertility',
                        path: 'fertility',
                        component: Section,
                        redirect: { name: 'admin-clients-mares-fertility-overview' },
                        children: [
                          {
                            name: 'admin-clients-mares-fertility-overview',
                            path: '',
                            component: () => import('@/views/admin/MaresFertility.vue'),
                            meta: {
                              search: true,
                            },
                            props: (route) => ({
                              route: 'admin-clients-mares-fertility-edit',
                              id: +route.params.mare,
                              showAdd: true,
                              add: { name: 'admin-clients-mares-fertility-add' },
                            }),
                          },
                          {
                            name: 'admin-clients-mares-fertility-add',
                            path: 'add',
                            component: () => import('@/views/admin/MaresFertilityAdd.vue'),
                          },
                          {
                            name: 'admin-clients-mares-fertility-edit',
                            path: ':cycle',
                            component: () => import('@/views/admin/MaresFertilityEdit.vue'),
                            props: (route) => ({
                              id: +route.params.cycle,
                              mare: { id: +route.params.mare },
                            }),
                          },
                        ],
                      },
                      {
                        name: 'admin-clients-mares-activities',
                        path: 'activities',
                        component: Section,
                        redirect: { name: 'admin-clients-mares-activities-overview' },
                        children: [
                          {
                            name: 'admin-clients-mares-activities-overview',
                            path: '',
                            component: () => import('@/views/admin/MaresActivity.vue'),
                            props: {
                              showAdd: true,
                              fertility: 'admin-mares-fertility-edit',
                              embryo: 'admin-embryos-edit',
                            },
                          },
                        ],
                      },
                      {
                        name: 'admin-clients-mares-medication',
                        path: 'medication',
                        component: Section,
                        redirect: { name: 'admin-clients-mares-medication-overview' },
                        children: [
                          {
                            name: 'admin-clients-mares-medication-overview',
                            path: '',
                            component: () => import('@/views/admin/MaresMedication.vue'),
                            props: {
                              showAdd: true,
                            },
                          },
                        ],
                      },
                      {
                        name: 'admin-clients-mares-stabling',
                        path: 'stabling',
                        component: Section,
                        redirect: { name: 'admin-clients-stabling-overview' },
                        children: [
                          {
                            name: 'admin-clients-stabling-overview',
                            path: '',
                            component: () => import('@/views/admin/MaresStabling.vue'),
                            props: (route) => ({
                              clientId: +route.params.client,
                              add: 'admin-clients-stabling-add',
                              edit: 'admin-clients-stabling-edit',
                            }),
                          },
                          {
                            path: ':stabling/edit',
                            name: 'admin-clients-stabling-edit',
                            component: () => import('@/views/admin/StablingsEdit.vue'),
                            props: (route) => ({
                              id: +route.params.stabling,
                              overview: 'admin-clients-stabling-overview',
                            }),
                          },
                          {
                            path: 'add',
                            name: 'admin-clients-stabling-add',
                            component: () => import('@/views/admin/StablingsAdd.vue'),
                            props: () => ({
                              overview: 'admin-clients-stabling-overview',
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'admin-clients-embryos',
                path: 'embryos',
                component: Section,
                redirect: { name: 'admin-clients-embryos-overview' },
                children: [
                  {
                    name: 'admin-clients-embryos-overview',
                    path: '',
                    meta: { search: true },
                    component: () => import('@/views/admin/ClientsEmbryos.vue'),
                    props: (route) => ({
                      route: 'admin-clients-embryos-overview',
                      client: { id: +route.params.client },
                    }),
                  },
                  {
                    name: 'admin-clients-embryos-edit',
                    path: ':embryo/edit',
                    component: () => import('@/views/admin/EmbryosEdit.vue'),
                    props: (route) => ({
                      id: +route.params.embryo,
                      overview: 'admin-clients-embryos-overview',
                    }),
                  },
                  {
                    name: 'admin-clients-embryos-add',
                    path: 'add',
                    component: () => import('@/views/admin/EmbryosAdd.vue'),
                    props: () => ({
                      overview: 'admin-clients-embryos-overview',
                    }),
                  },
                ],
              },
              {
                name: 'admin-clients-invoices',
                path: 'invoices',
                component: Section,
                redirect: { name: 'admin-clients-invoices-overview' },
                meta: { search: true },
                children: [
                  {
                    name: 'admin-clients-invoices-overview',
                    path: '',
                    component: () => import('@/views/admin/ClientsInvoices.vue'),
                    props: (route) => ({
                      client: { id: +route.params.client },
                      route: 'admin-clients-invoices-edit',
                    }),
                  },
                  {
                    path: ':invoice',
                    name: 'admin-clients-invoices-edit',
                    props: (route) => ({
                      id: +route.params.invoice,
                      overview: 'admin-clients-invoices-overview',
                      client: { id: +route.params.client },
                    }),
                    component: () => import('@/views/admin/InvoicesEdit.vue'),
                  },
                  {
                    name: 'admin-clients-invoices-add',
                    props: () => ({
                      overview: 'admin-clients-invoices-overview',
                    }),
                    path: 'add',
                    component: () => import('@/views/admin/InvoicesAdd.vue'),
                  },
                ],
              },
              {
                name: 'admin-clients-contracts',
                path: 'contracts',
                component: Section,
                redirect: { name: 'admin-clients-contracts-overview' },
                children: [
                  {
                    name: 'admin-clients-contracts-overview',
                    path: '',
                    meta: { search: true },
                    component: () => import('@/views/admin/ClientsContracts.vue'),
                    props: (route) => ({
                      route: 'admin-clients-contracts-overview',
                      client: { id: +route.params.client },
                    }),
                  },
                  {
                    path: 'add',
                    name: 'admin-clients-contracts-add',
                    component: () => import('@/views/admin/ContractsAdd.vue'),
                    props: () => ({
                      overview: 'admin-clients-contracts-overview',
                    }),
                  },
                  {
                    name: 'admin-clients-contracts-edit',
                    path: ':contract/edit',
                    component: () => import('@/views/admin/ContractsEdit.vue'),
                    props: (route) => ({
                      id: +route.params.contract,
                      overview: 'admin-clients-contracts-overview',
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'mares',
        name: 'admin-mares',
        component: Section,
        redirect: { name: 'admin-mares-overview' },
        children: [
          {
            path: '',
            name: 'admin-mares-overview',
            component: () => import('@/views/admin/Mares.vue'),
            meta: { search: true },
          },
          {
            path: 'add',
            name: 'admin-mares-add',
            component: () => import('@/views/admin/MaresAdd.vue'),
            props: () => ({
              overview: 'admin-mares-overview',
            }),
          },
          {
            name: 'admin-mare',
            path: ':mare',
            redirect: { name: 'admin-mares-edit' },
            component: () => import('@/views/admin/MaresShow.vue'),
            props: (route) => ({
              id: +route.params.mare,
              overview: 'admin-mares',
              tabs: [
                { name: t('general.details'), key: 'admin-mares-edit' },
                { name: t('mares.fertility'), key: 'admin-mares-fertility' },
                { name: t('activities.title'), key: 'admin-mares-activities' },
                { name: t('medications.title'), key: 'admin-mares-medications' },
                { name: t('stablings.title'), key: 'admin-mares-stablings' },
                { name: t('embryos.title'), key: 'admin-mares-embryos' },
              ],
            }),
            children: [
              {
                name: 'admin-mares-edit',
                path: 'edit',
                component: () => import('@/views/admin/MaresEdit.vue'),
              },
              {
                name: 'admin-mares-fertility',
                path: 'fertility',
                component: Section,
                redirect: { name: 'admin-mares-fertility-overview' },
                meta: {
                  search: true,
                },
                children: [
                  {
                    name: 'admin-mares-fertility-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresFertility.vue'),
                    props: (route) => ({
                      id: +route.params.mare,
                      showAdd: true,
                      route: 'admin-mares-fertility-edit',
                    }),
                  },
                  {
                    name: 'admin-mares-fertility-add',
                    path: 'add',
                    component: () => import('@/views/admin/MaresFertilityAdd.vue'),
                  },
                  {
                    name: 'admin-mares-fertility-edit',
                    path: ':cycle',
                    component: () => import('@/views/admin/MaresFertilityEdit.vue'),
                    props: (route) => ({
                      id: +route.params.cycle,
                    }),
                  },
                ],
              },
              {
                name: 'admin-mares-activities',
                path: 'activities',
                component: Section,
                redirect: { name: 'admin-mares-activities-overview' },
                children: [
                  {
                    name: 'admin-mares-activities-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresActivity.vue'),
                    props: () => ({
                      fertility: 'admin-mares-fertility-edit',
                      embryo: 'admin-embryos-edit',
                      showAdd: true,
                    }),
                  },
                  {
                    name: 'admin-mares-activities-add',
                    path: 'add',
                    component: () => import('@/views/admin/MaresActivityAdd.vue'),
                    props: () => ({
                      overview: 'admin-mares-activities-overview',
                    }),
                  },
                ],
              },
              {
                name: 'admin-mares-medications',
                path: 'medications',
                component: Section,
                redirect: { name: 'admin-mares-medications-overview' },
                children: [
                  {
                    name: 'admin-mares-medications-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresMedication.vue'),
                    props: {
                      showAdd: true,
                    },
                  },
                  {
                    name: 'admin-mares-medications-add',
                    path: 'add',
                    component: () => import('@/views/admin/MaresMedicationAdd.vue'),
                    props: () => ({
                      overview: 'admin-mares-medications-overview',
                    }),
                  },
                ],
              },
              {
                name: 'admin-mares-stablings',
                path: 'stablings',
                component: Section,
                redirect: { name: 'admin-mares-stablings-overview' },
                children: [
                  {
                    name: 'admin-mares-stablings-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresStabling.vue'),
                    props: () => ({
                      add: 'admin-mares-stablings-add',
                      edit: 'admin-mares-stablings-edit',
                    }),
                  },
                  {
                    path: ':stabling/edit',
                    name: 'admin-mares-stablings-edit',
                    component: () => import('@/views/admin/StablingsEdit.vue'),
                    props: (route) => ({
                      id: +route.params.stabling,
                      overview: 'admin-mares-stablings-overview',
                    }),
                  },
                  {
                    path: 'add',
                    name: 'admin-mares-stablings-add',
                    component: () => import('@/views/admin/StablingsAdd.vue'),
                    props: () => ({
                      overview: 'admin-mares-stablings-overview',
                      showMare: false,
                    }),
                  },
                ],
              },
              {
                name: 'admin-mares-embryos',
                path: 'embryos',
                component: () => import('@/views/admin/MaresEmbryos.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'embryos',
        name: 'admin-embryos',
        component: Section,
        redirect: { name: 'admin-embryos-overview' },
        children: [
          {
            path: '',
            name: 'admin-embryos-overview',
            component: () => import('@/views/admin/Embryos.vue'),
            meta: { search: true },
          },
          {
            path: 'add',
            name: 'admin-embryos-add',
            component: () => import('@/views/admin/EmbryosAdd.vue'),
            props: () => ({
              overview: 'admin-embryos-overview',
            }),
          },
          {
            name: 'admin-embryos-edit',
            path: ':embryo/edit',
            component: () => import('@/views/admin/EmbryosEdit.vue'),
            props: (route) => ({
              id: +route.params.embryo,
              overview: 'admin-embryos-overview',
            }),
          },
        ],
      },
      {
        path: 'sperm',
        name: 'admin-sperms',
        component: Section,
        redirect: { name: 'admin-sperms-overview' },
        children: [
          {
            path: '',
            name: 'admin-sperms-overview',
            component: () => import('@/views/admin/Sperms.vue'),
            meta: { search: true },
          },
          {
            name: 'admin-sperms-edit',
            path: ':sperm/edit',
            component: () => import('@/views/admin/SpermsEdit.vue'),
            props: (route) => ({
              id: +route.params.sperm,
              overview: 'admin-sperms-overview',
            }),
          },
          {
            name: 'admin-sperms-add',
            path: 'add',
            component: () => import('@/views/admin/SpermsAdd.vue'),
            props: () => ({
              overview: 'admin-sperms-overview',
            }),
          },
        ],
      },
      {
        path: 'stablings',
        name: 'admin-stablings',
        component: Section,
        redirect: { name: 'admin-stablings-overview' },
        children: [
          {
            path: '',
            name: 'admin-stablings-overview',
            component: () => import('@/views/admin/Stablings.vue'),
            meta: { search: true },
          },
          {
            path: ':stabling/edit',
            name: 'admin-stablings-edit',
            component: () => import('@/views/admin/StablingsEdit.vue'),
            props: (route) => ({
              id: +route.params.stabling,
              overview: 'admin-stablings-overview',
            }),
          },
          {
            path: 'add',
            name: 'admin-stablings-add',
            component: () => import('@/views/admin/StablingsAdd.vue'),
            props: () => ({
              overview: 'admin-stablings-overview',
            }),
          },
        ],
      },
      {
        name: 'admin-invoices',
        path: 'invoices',
        component: Section,
        redirect: { name: 'admin-invoices-overview' },
        meta: { search: true },
        children: [
          {
            name: 'admin-invoices-overview',
            path: '',
            component: () => import('@/views/admin/Invoices.vue'),
          },
          {
            path: ':invoice',
            name: 'admin-invoices-edit',
            props: (route) => ({
              id: +route.params.invoice,
              overview: 'admin-invoices-overview',
            }),
            component: () => import('@/views/admin/InvoicesEdit.vue'),
          },
          {
            name: 'admin-invoices-add',
            path: 'add',
            props: () => ({
              overview: 'admin-invoices-overview',
            }),
            component: () => import('@/views/admin/InvoicesAdd.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: 'admin-settings',
        component: () => import('@/views/admin/Settings.vue'),
        props: () => ({
          tabs: [
            { name: t('users.title'), key: 'admin-settings-users' },
            { name: t('stallions.title'), key: 'admin-settings-stallions' },
            { name: t('billabletypes.title'), key: 'admin-settings-billabletypes' },
            { name: t('medications.title'), key: 'admin-settings-medications' },
            { name: t('files.title'), key: 'admin-settings-files' },
          ],
        }),
        redirect: { name: 'admin-settings-users' },
        children: [
          {
            path: 'stallions',
            name: 'admin-settings-stallions',
            component: Section,
            redirect: { name: 'admin-settings-stallions-overview' },
            children: [
              {
                path: '',
                name: 'admin-settings-stallions-overview',
                component: () => import('@/views/admin/Stallions.vue'),
                meta: { search: true },
              },
              {
                path: 'add',
                name: 'admin-settings-stallions-add',
                component: () => import('@/views/admin/StallionsAdd.vue'),
                props: () => ({
                  overview: 'admin-settings-stallions-overview',
                }),
              },
              {
                name: 'admin-settings-stallions-edit',
                path: ':stallion',
                component: () => import('@/views/admin/StallionsEdit.vue'),
                props: (route) => ({
                  id: +route.params.stallion,
                  overview: 'admin-settings-stallions-overview',
                }),
              },
            ],
          },
          {
            name: 'admin-settings-users',
            path: 'users',
            component: Section,
            redirect: { name: 'admin-settings-users-overview' },
            meta: { search: true },
            children: [
              {
                name: 'admin-settings-users-overview',
                path: '',
                component: () => import('@/views/admin/Users.vue'),
              },
              {
                path: ':id',
                name: 'admin-settings-users-edit',
                props: (route) => ({ id: +route.params.id }),
                component: () => import('@/views/admin/UsersEdit.vue'),
              },
              {
                name: 'admin-settings-users-add',
                path: 'add',
                component: () => import('@/views/admin/UsersAdd.vue'),
              },
            ],
          },
          {
            name: 'admin-settings-billabletypes',
            path: 'billable-types',
            component: Section,
            redirect: { name: 'admin-settings-billabletypes-overview' },
            children: [
              {
                name: 'admin-settings-billabletypes-overview',
                path: '',
                component: () => import('@/views/admin/BillableTypes.vue'),
              },
              {
                name: 'admin-settings-billabletypes-add',
                path: 'add',
                component: () => import('@/views/admin/BillableTypesAdd.vue'),
              },
              {
                path: ':billabletypes',
                name: 'admin-settings-billabletypes-edit',
                props: (route) => ({ id: +route.params.billabletypes }),
                component: () => import('@/views/admin/BillableTypesEdit.vue'),
              },
            ],
          },
          {
            name: 'admin-settings-medications',
            path: 'medications',
            component: Section,
            redirect: { name: 'admin-settings-medications-overview' },
            children: [
              {
                name: 'admin-settings-medications-overview',
                path: '',
                component: () => import('@/views/admin/Medications.vue'),
              },
              {
                path: ':id',
                name: 'admin-settings-medications-edit',
                props: (route) => ({
                  id: +route.params.id,
                  overview: 'admin-settings-medications-overview',
                }),
                component: () => import('@/views/admin/MedicationsEdit.vue'),
              },
              {
                path: 'add',
                name: 'admin-settings-medications-add',
                props: () => ({
                  overview: 'admin-settings-medications-overview',
                }),
                component: () => import('@/views/admin/MedicationsAdd.vue'),
              },
            ],
          },
          {
            name: 'admin-settings-files',
            path: 'files',
            component: () => import('@/views/admin/Files.vue'),
          },
        ],
      },
      {
        path: 'account',
        name: 'admin-account',
        component: () => import('@/views/admin/Account.vue'),
      },
    ],
  },
  {
    name: 'portal',
    path: '/portal',
    component: () => import('./views/Portal.vue'),
    redirect: { name: 'portal-dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'portal-dashboard',
        component: () => import('@/views/portal/Dashboard.vue'),
      },
      {
        path: 'mares',
        name: 'portal-mares',
        component: Section,
        redirect: { name: 'portal-mares-overview' },
        meta: { search: true },
        children: [
          {
            name: 'portal-mares-overview',
            path: '',
            component: () => import('@/views/portal/Mares.vue'),
            props: () => ({ route: 'portal-mare' }),
          },
          {
            name: 'portal-mare',
            path: ':mare',
            redirect: { name: 'portal-mares-view' },
            component: () => import('@/views/admin/MaresShow.vue'),
            props: (route) => ({
              id: +route.params.mare,
              overview: 'portal-mares',
              tabs: [
                { name: t('general.details'), key: 'portal-mares-view' },
                { name: t('mares.fertility'), key: 'portal-mares-fertility' },
                { name: t('activities.title'), key: 'portal-mares-activities' },
                { name: t('medications.title'), key: 'portal-mares-medication' },
                { name: t('stablings.title'), key: 'portal-mares-stabling' },
              ],
            }),
            children: [
              {
                name: 'portal-mares-view',
                path: 'edit',
                component: () => import('@/views/portal/MaresDetail.vue'),
              },
              {
                name: 'portal-mares-fertility',
                path: 'fertility',
                component: Section,
                redirect: { name: 'portal-mares-fertility-overview' },
                meta: {
                  search: true,
                },
                children: [
                  {
                    name: 'portal-mares-fertility-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresFertility.vue'),
                    props: (route) => ({
                      id: +route.params.mare,
                      route: 'portal-mares-fertility-view',
                    }),
                  },
                  {
                    name: 'portal-mares-fertility-view',
                    path: ':cycle',
                    component: () => import('@/views/portal/MaresFertilityShow.vue'),
                    props: (route) => ({
                      id: +route.params.cycle,
                    }),
                  },
                ],
              },
              {
                name: 'portal-mares-activities',
                path: 'activities',
                component: Section,
                redirect: { name: 'portal-mares-activities-overview' },
                meta: {
                  search: true,
                },
                children: [
                  {
                    name: 'portal-mares-activities-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresActivity.vue'),
                    props: (route) => ({
                      id: +route.params.mare,
                      fertility: 'portal-mares-fertility-view',
                      embryo: 'portal-embryos-view',
                    }),
                  },
                ],
              },
              {
                name: 'portal-mares-medication',
                path: 'medication',
                component: Section,
                redirect: { name: 'portal-mares-medication-overview' },
                children: [
                  {
                    name: 'portal-mares-medication-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresMedication.vue'),
                    props: (route) => ({
                      id: +route.params.mare,
                    }),
                  },
                ],
              },
              {
                name: 'portal-mares-stabling',
                path: 'stabling',
                component: Section,
                redirect: { name: 'portal-mares-stabling-overview' },
                children: [
                  {
                    name: 'portal-mares-stabling-overview',
                    path: '',
                    component: () => import('@/views/admin/MaresStabling.vue'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'portal-embryos',
        path: 'embryos',
        component: Section,
        redirect: { name: 'portal-embryos-overview' },
        children: [
          {
            name: 'portal-embryos-overview',
            path: '',
            meta: { search: true },
            component: () => import('@/views/portal/Embryos.vue'),
            props: () => ({
              route: 'portal-embryos-overview',
            }),
          },
          {
            name: 'portal-embryos-view',
            path: ':embryo/view',
            component: () => import('@/views/portal/EmbryosShow.vue'),
            props: (route) => ({
              id: +route.params.embryo,
              overview: 'portal-embryos-overview',
            }),
          },
        ],
      },
      {
        path: 'sperm',
        name: 'portal-sperms',
        component: Section,
        redirect: { name: 'portal-sperms-overview' },
        children: [
          {
            path: '',
            name: 'portal-sperms-overview',
            component: () => import('@/views/portal/Sperms.vue'),
            meta: { search: true },
          },
          {
            name: 'portal-sperms-view',
            path: ':sperm/view',
            component: () => import('@/views/portal/SpermsShow.vue'),
            props: (route) => ({
              id: +route.params.sperm,
              overview: 'portal-sperms-overview',
            }),
          },
        ],
      },
      {
        name: 'portal-stabling',
        path: 'stabling',
        component: Section,
        redirect: { name: 'portal-stabling-overview' },
        children: [
          {
            name: 'portal-stabling-overview',
            path: '',
            meta: { search: true },
            component: () => import('@/views/portal/Stablings.vue'),
          },
          {
            path: ':stabling/view',
            name: 'portal-stabling-view',
            component: () => import('@/views/portal/StablingsShow.vue'),
            props: (route) => ({
              id: +route.params.stabling,
              overview: 'portal-stabling-overview',
            }),
          },
        ],
      },
      {
        name: 'portal-contracts',
        path: 'contracts',
        component: Section,
        redirect: { name: 'portal-contracts-overview' },
        children: [
          {
            name: 'portal-contracts-overview',
            path: '',
            meta: { search: true },
            component: () => import('@/views/portal/Contracts.vue'),
            props: (route) => ({
              route: 'portal-contracts-overview',
              client: { id: +route.params.client },
            }),
          },
        ],
      },
      {
        name: 'portal-invoices',
        path: 'invoices',
        component: () => import('@/views/portal/Invoices.vue'),
      },
      {
        path: 'account',
        name: 'portal-account',
        component: () => import('@/views/admin/Account.vue'),
      },
    ],
  },
];
